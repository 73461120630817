import mobile from "is-mobile";
import React from "react";
import { Tooltip } from "react-tooltip";

import EventStatusBadge from "./EventStatusBadge";

import useFetch from "./useFetch";
import { EVENT_STATUS } from "./client-consts";
import { shortDate } from "./date-helpers";
import { useNavigate } from "react-router-dom";
import EventSearchStatus from "./EventSearchStatus";
import EditEventPopupButton from "./EditEventPopupButton";

const EventDetailsBox = ({
  event,
  updateEventData,
  fetchEventSearchStatus,
  eventSearchStatus,
}) => {
  return (
    <>
      <div className="EventDetailsBox">
        <div className="d-flex align-items-center justify-content-between">
          <div
            className={`d-flex align-items-center font-xxl bold ${
              event.status === EVENT_STATUS.ACTIVE ? "green-text" : "blue-text"
            }`}
          >
            <div>
              <span>האירוע של</span>
              <span className="ph-text">{event.name}</span>
            </div>
          </div>
          <EventStatusBadge status={event.status} size="large" />
        </div>

        <div className="thin d-flex font-xl pt24">
          <div className="pl-text">תאריך האירוע |</div>
          <div className="purple-text">{shortDate(event.date)}</div>

          <EditEventPopupButton
            event={event}
            updateEventData={updateEventData}
          />
        </div>

        {event.status === EVENT_STATUS.FUTURE ? (
          <div className="font-l pt24">
            <OpenEventButton event={event} />
          </div>
        ) : null}

        {event.status === EVENT_STATUS.ACTIVE ? (
          <div className="font-xl pt24">
            <EventCloseAt event={event} />
          </div>
        ) : null}

        <EventText
          event={event}
          fetchEventSearchStatus={fetchEventSearchStatus}
          eventSearchStatus={eventSearchStatus}
        />
      </div>
    </>
  );
};

const EventText = ({ event, fetchEventSearchStatus, eventSearchStatus }) => {
  return (
    <>
      {/* {event.status === EVENT_STATUS.FUTURE && ( */}
      {/*   <> */}
      {/*     <div className="mt40 mb40 divider"></div> */}
      {/*     <div className="font-l"> */}
      {/*       <div className=""> */}
      {/*         שימו 💜 מרגע הפעלת האירוע הוא ייסגר באופן אוטומטי לאחר 3 שבועות. */}
      {/*       </div> */}
      {/*       <div className="pt24"> */}
      {/*         המלצת צלמי PicMe - הפעילו את האירוע כשבוע לפני יום האירוע , על מנת */}
      {/*         שלאחר יום האירוע יהיה ניתן במשך שבועיים להעלות את התמונות לאתר */}
      {/*         ולשלוח אותן לאורחים. */}
      {/*       </div> */}
      {/*     </div> */}
      {/*   </> */}
      {/* )} */}

      {event.status === EVENT_STATUS.PAST && (
        <>
          <div className="font-l center">
            <div className="mt24 pb24 d-flex justify-content-center">
              <EventSearchStatus
                event={event}
                eventSearchStatus={eventSearchStatus}
                fetchEventSearchStatus={fetchEventSearchStatus}
              />
            </div>
            <div className="pt24">
              <div>האירוע סגור - לא ניתן להעלות תמונות.</div>
              <div>האורחים יכולים לשלוח סלפי ולקבל את התמונות שלהם עד שלושה חודשים מרגע סגירת האירוע.</div>
              
            </div>
            <div className="pt24 thin">
              במידה וקרתה תקלה או בעיה אחרת באירוע זה, אנא צרו קשר עם מוקד
              התמיכה ונשמח לעזור!
            </div>
          </div>
        </>
      )}
    </>
  );
};

const OpenEventButton = ({ event }) => {
  const navigate = useNavigate();

  const { fetchData: updateEventStatus } = useFetch({
    onSuccess: () => navigate(0),
  });
  const onActivateEventClick = () => {
    const isConfirmed = window.confirm(
      "האם אתה בטוח שברצונך להפעיל אירוע זה? מרגע הפעלת האירוע הוא ייסגר באופן אוטומטי לאחר שבוע. לא יהיה ניתן להעלות תמונות לאירוע סגור."
    );
    if (!isConfirmed) {
      return;
    }

    updateEventStatus({
      query: "updateEventStatus",
      params: {
        eventId: event._id.toString(),
        status: EVENT_STATUS.ACTIVE,
      },
    });
  };

  return (
    <>
      <div className="font-xl thin d-flex pt12">
        מרגע הפעלת האירוע הוא ייסגר באופן אוטומטי לאחר שבוע
      </div>
      <div className="mt24 d-flex-mobile align-items-center">
        <button
          className="big-button green-button"
          onClick={onActivateEventClick}
        >
          הפעל את האירוע
        </button>
        {/* <div className={`${mobile() ? "pt12" : "pr24"} thin pl24`}> */}
        {/*   בהפעלת האירוע יהיה ניתן להעלות תמונות. */}
        {/* </div> */}
      </div>
    </>
  );
};

const EventCloseAt = ({ event }) => {
  const navigate = useNavigate();

  const { fetchData: closeEvent } = useFetch({
    // refresh
    onSuccess: () => navigate(0),
  });
  const onCloseEventClick = () => {
    const isConfirmed = window.confirm(
      "האם אתה בטוח שברצונך לסגור אירוע זה? לא יהיה ניתן להעלות תמונות לאחר סגירת האירוע."
    );
    if (!isConfirmed) {
      return;
    }
    closeEvent({
      query: "closeEvent",
      params: {
        eventId: event._id.toString(),
      },
    });
  };

  const deadline = new Date(event.forceCloseAt);

  const day = deadline.getDate();
  const month = deadline.getMonth() + 1; // Note: Months are zero-indexed, so we add 1
  const year = deadline.getFullYear();
  const hours = deadline.getHours();
  const minutes = deadline.getMinutes();

  return (
    <>
      <div className="thin d-flex align-items-center justify-content-between">
        <Tooltip id="closeEvent">
          אירוע פעיל במשך שבוע ונסגר באופן אוטומטי לאחר מכן. לא יהיה ניתן להעלות
          תמונות לאירוע סגור.
        </Tooltip>
        <div data-tooltip-id="closeEvent" className="pl24">
          <span>האירוע ייסגר ב - </span>
          <span className="red-text">{`${day}/${month}/${year} בשעה ${hours}:${minutes}`}</span>
        </div>
      </div>
      <button className="mt24 danger-button" onClick={onCloseEventClick}>
        סגור את האירוע עכשיו
      </button>
    </>
  );
};

export default EventDetailsBox;
