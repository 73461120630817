import React from "react";

import useFetch from "./useFetch";
import { useNavigate } from "react-router-dom";

const CloseFutureEvent = ({ event }) => {
  const navigate = useNavigate();

  const { fetchData: closeEvent } = useFetch({
    // refresh
    onSuccess: () => navigate(0),
  });
  const onCloseEventClick = () => {
    const isConfirmed = window.confirm(
      "האם אתה בטוח שברצונך לסגור אירוע זה? לא יהיה ניתן להעלות תמונות לאחר סגירת האירוע."
    );
    if (!isConfirmed) {
      return;
    }
    closeEvent({
      query: "closeEvent",
      params: {
        eventId: event._id.toString(),
      },
    });
  };

  return (
    <div className="d-flex-column align-items-center">
      <div className="mb24 thin font-l pl24">
        האירוע לא קורה בסוף? אין בעיה! סגור את האירוע לפני הפעלתו והוא יחזור לסך
        האירועים לזכותך.
      </div>

      <button className="mb24 danger-button" onClick={onCloseEventClick}>
        סגור את האירוע עכשיו
      </button>
    </div>
  );
};

export default CloseFutureEvent;
